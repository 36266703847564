import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessagingService } from 'src/app/messaging.service';
@Component({
  selector: 'vc-app-notification',
  template: `
 <h1 mat-dialog-title>Notification Permission</h1>
<div mat-dialog-content>
<p [innerHTML]="data.message"></p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-raised-button color="warn" (click)="close()">Close</button>
  <button mat-button mat-raised-button color="primary" *ngIf="data.showAllow" (click)="userRequestedNotificationPermission()" cdkFocusInitial>Allow</button>
</div>
`,
  styleUrls: ['./app-notification.component.scss']
})
export class AppNotificationComponent implements OnInit {
  isPermissionGranted: boolean;

  constructor(private messagingService: MessagingService, public dialogRef: MatDialogRef<AppNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, showAllow: boolean }, private dialog: MatDialog) {
  }

  ngOnInit(): void {

  }
 
  async userRequestedNotificationPermission() {
    this.isPermissionGranted = this.messagingService.isPermissionGranted();
    console.log('isPermissionGranted', this.isPermissionGranted);
    if (!this.isPermissionGranted) {
      this.data.message = "Notifications are are being enabled.";
      this.data.showAllow = false;
      await this.messagingService.requestNotificationPermission();
      this.dialog.open(AppNotificationComponent, {
        data: {
          message: "Notifications are enabled.<br/>You will now receive updates.",
          showAllow:false
        }, // Optional: Prevent closing the dialog without user action
      });
    } else {
      console.log('Permission already granted');
    }
    this.dialogRef.close();
  }


  close() {
    this.dialogRef.close();
  }
}
