import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication/authentication.service';
import { AppNotificationComponent } from './shared/components/app-notification/app-notification.component';
import { ImageUrl } from './shared/constants/image--url-constant';
import { SwUpdate } from '@angular/service-worker';
import { MessagingService } from './messaging.service';
import { TitleService } from './title.service';
import { AppInsightsService } from './framework/service/app-insights.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'vc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  logo: string;
  clientDetails;
  programmeDetails: any;
  appDetails
  getImagePath;
 
  constructor(
    private readonly title: Title,
    private readonly authenticationService: AuthenticationService,
    private readonly renderer: Renderer2,
    private swUpdate: SwUpdate,
    private titleService: TitleService,
    private readonly appInsightsService: AppInsightsService,
    private dialog: MatDialog,
    private messagingService: MessagingService
  ) {
    this.title.setTitle('Sign In');       

  }

  ngOnInit() {
    this.openNotificationDialogOrRequestPermission();
    this.loadApplication();
  }

     @HostListener('keyup',['$event']) keyUpEvent(event: Event) {
      if(localStorage.getItem('userId')){
        this.appInsightsService.logEvent(""+event.type,{'userId':localStorage.getItem('userId') , session_uuid:localStorage.getItem('session_uuid')});
      }else{
        this.appInsightsService.logEvent(""+event.type);
      }
    }
    @HostListener('click',['$event']) clickEvent(event: Event) {
      if(localStorage.getItem('userId')){
        this.appInsightsService.logEvent(""+event.type , {'userId':localStorage.getItem('userId') , session_uuid:localStorage.getItem('session_uuid')});
     
      }else{
        this.appInsightsService.logEvent(""+event.type);
      }
    }
    @HostListener('dblclick',['$event']) doubleClick(event: Event) {
      this.appInsightsService.logEvent(""+event.type);
    }
    @HostListener('submit',['$event']) submit(event: Event) {
      this.appInsightsService.logEvent(""+event.type);
    }
    @HostListener('drag',['$event']) drag(event: Event) {
      this.appInsightsService.logEvent(""+event.type);
    }
    @HostListener('drop',['$event']) drop(event: Event) {
      this.appInsightsService.logEvent(""+event.type);
    }

    @HostListener('window:scroll',['$event']) scroll(event: Event) {
      if (localStorage.getItem('userId')) {
        this.appInsightsService.logEvent("" + event.type, { 'userId': localStorage.getItem('userId') , session_uuid:localStorage.getItem('session_uuid') });
      } else {
        this.appInsightsService.logEvent("" + event.type);
      }
    }

  
   loadApplication(){
    if (!environment.enableCtrl) {
      this.disableControls();
    }
    if (this.swUpdate.isEnabled) {
      console.log("Serviceworker update enabled ");
      this.swUpdate.activated.subscribe((upd) => {
        console.info("received update"+upd+" and activated");
        window.location.reload();
      });
      this.swUpdate.available.subscribe((upd) => {
        console.log("New version avilable "+upd);
        
        this.swUpdate.activateUpdate();
      }, (error) => {
        console.error(error);
      });

      this.swUpdate.checkForUpdate().then(() => {
        console.log("Got new Version");
      }).catch((error) => {
        console.error('Could not check for app updates', error);
      });
    }

    
    
      this.authenticationService.getClientDetails().subscribe(data => {
        this.clientDetails = data;
        localStorage.setItem('clientId', this.clientDetails.id);
        localStorage.setItem('ApplicationID',this.clientDetails.appId);
        localStorage.setItem('moodleUrl',this.clientDetails.moodleUrl)
        localStorage.setItem('isSelfRegisterAllowed',this.clientDetails.isSelfRegisterAllowed)
      });

      this.getAppInfoFromOrgranizationId()
  }

  getAppInfoFromOrgranizationId() {  
    const orgId = JSON.parse(atob(localStorage.getItem('sessionToken').split('.')[1])).orgId;      
    this.authenticationService.getAppInfoByOrgId(orgId).subscribe(data => {
      
      localStorage.setItem('primaryAppColor',data.organizationUnitExtdRWS.primaryAppColour)
      localStorage.setItem('secondaryAppColor',data.organizationUnitExtdRWS.secondaryAppColour)
      
      document.documentElement.style.setProperty('--primary-color', data.organizationUnitExtdRWS.primaryAppColour);
      document.documentElement.style.setProperty('--secondary-color', data.organizationUnitExtdRWS.secondaryAppColour);
     
      this.getImagePath = ImageUrl.IMAGES + data.organizationUnitExtdRWS.logoPath;
    
      localStorage.setItem('appLogo',this.getImagePath)
      console.log(this.getImagePath);
    })
    

  
  }

  disableControls() {

    function keyPressCheck(event) {
      if (event.keyCode === 123) {
        return false;
      }
    }

    document.oncontextmenu = function () {
      return false;
    }

    function disableCtrlKeyCombination(e) {
      //list all CTRL + key combinations you want to disable
      const forbiddenKeys = new Array('a', 'n', 'c', 'x', 'v', 'j', 'w');
      let key, isCtrl;

      if (window.event) {
        key = (<any>window.event).keyCode; //IE
        if ((<any>window.event).ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      } else {
        key = e.which; //firefox
        if (e.ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      }
      //if ctrl is pressed check if other key is in forbidenKeys array
      if (isCtrl) {
        for (let i = 0; i < forbiddenKeys.length; i++) {
          //case-insensitive comparation
          if (forbiddenKeys[i].toLowerCase() === String.fromCharCode(key).toLowerCase()) {
            return false;
          }
        }
      }
      return true;
    }
  }
  
  openNotificationDialogOrRequestPermission(): void {
    try{
      let checkNotificationStatusObject = this.messagingService.checkNotificationStatus();
      if (checkNotificationStatusObject["showNotificationDialog"]) {
        this.dialog.open(AppNotificationComponent, {
          data: {
            message: checkNotificationStatusObject["message"],
            showAllow:true
          },
          disableClose: true, // Optional: Prevent closing the dialog without user action
        });
      } else {
        console.log('Permission already granted');
        this.messagingService.requestNotificationPermission();
      }
      
    }catch(error){
      console.log("Error while opening notification dialog ",error);
    }
  }


}
