import { ApplicationConstant } from "src/app/shared/constants/app-constant";

export const environment = {
  production: true,
  api: 'https://devservices.sequation.net',
  enableCtrl: true,
  azureBlobStorage: 'https://sequationdevsav2.blob.core.windows.net',
  azureCDNStorageUrl: 'https://sequation-dev-cdn-v2.sequation.net',
  appInsights: {
    instrumentationKey: '684b89ec-2ad7-4fee-b124-605031c60718'
  },

  env: ApplicationConstant.DEV_ENV,

  firebaseConfig: {
    apiKey: "AIzaSyBA3lGuFXaDEnOe0MfWmPIreZWOQSMFFV0",
    authDomain: "captr-push-notifications.firebaseapp.com",
    projectId: "captr-push-notifications",
    storageBucket: "captr-push-notifications.appspot.com",
    messagingSenderId: "370899662143",
    appId: "1:370899662143:web:057b8ff936c94228c38f8a",
    measurementId: "G-7DBQQ0Q8W9",
    vapidKey: 'BIOdVWhf9iOTI9bBl9LykNMxaEpIsMNgQW_YRaviGXhvXHTgwhBJjujwRN05cAEE_md_zbpjl-UFTKibPpqd6yo',
  },
  novuAppIdentifier : 'sQu7JbW3UmUA'

};
